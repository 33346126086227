import React from "react";
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps as MuiAccordionProps,
  Typography,
  styled,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const StyledAccordion = styled(MuiAccordion)({
  marginTop: "2rem",
  backgroundColor: "transparent",
  boxShadow: "none",
  "&::before": {
    display: "none"
  },
  "& a": {
    color: "inherit"
  }
});

const StyledAccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: theme.palette.primary.main
  },
  "&:not(.Mui-expanded)": {
    borderBottomColor: theme.palette.primary.main,
    borderBottomWidth: "1px",
    borderBottomStyle: "solid"
  }
}));

const StyledAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  "&:not(.MuiCollapse-hidden)": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: "0.5rem",
    padding: "2rem 3rem 3rem 2rem"
  }
}));

interface AccordionOwnProps {
  label: React.ReactNode;
}

export type AccordionProps = MuiAccordionProps & React.PropsWithChildren<AccordionOwnProps>

export default function Accordion({children, label, ...props}: AccordionProps) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => setExpanded(isExpanded);

  return (
    <StyledAccordion {...props} onChange={handleChange}>
      <StyledAccordionSummary expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}>
        <Typography dangerouslySetInnerHTML={{ __html: label }} />
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <Typography>{children}</Typography>
      </StyledAccordionDetails>
    </StyledAccordion>
  )
}