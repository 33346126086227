import React, {useState} from "react";
import { graphql } from "gatsby";
import latinize from "latinize"
import {Box, Button, Grid, Typography, TextField} from "@mui/material";
import { useTranslation, Helmet } from "gatsby-plugin-react-i18next";
import SiteLayout from "../components/layouts/SiteLayout";
import Section from "../components/molecules/Section";
import Accordion from "../components/molecules/Accordion";
import {StaticImage} from "gatsby-plugin-image";

interface Question {
  question: string;
  answer: string;
  keywords: string[];
}

interface Section {
  title: string;
  questions: Question[];
}

function filterSections(sections: Section[], search: string, language: string) {
  return sections.reduce((filteredSections, currentSection) => {
    if (search) {
      const normalizedSearch = latinize(search)
        .normalize("NFD")
        .toLocaleLowerCase(language);
      const phrases = normalizedSearch.split(" ").filter(phrase => phrase !== "");

      const filteredQuestions = currentSection.questions.filter(item => {
        const keywordsMatch = item.keywords.some(keyword => {
          return phrases.some(phrase => latinize(keyword).includes(phrase));
        })

        return keywordsMatch || latinize(item.answer)
          .normalize("NFD")
          .toLocaleLowerCase(language)
          .includes(normalizedSearch);
      })

      if (filteredQuestions.length) return [
        ...filteredSections,
        {
          ...currentSection,
          questions: filteredQuestions
        }
      ]
      else return filteredSections
    }

    return [...filteredSections, currentSection]
  }, [] as Section[])
}

function handleSearchAnalytics(search: string, sectionsLength: number) {
  // @ts-ignore
  const {dataLayer} = window;
  if (dataLayer) {
    dataLayer.push({ event: "search", search, sectionsLength })
  }
}

export default function FAQPage() {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState("");

  const sections = filterSections(t("sections", { returnObjects: true }) as Section[], search, i18n.language)

  return (
    <SiteLayout>
      <Helmet>
        <title>DokDok - FAQ</title>
      </Helmet>
      <Section padding={4}>
        <Grid container spacing={6} sx={{ md: {mt: "2rem", mb: "2rem" } }}>
          <Grid item textAlign="center" xs={12}>
            <Typography variant="h1" color="primary">
              {t("pageTitle")}
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <TextField
              onChange={e => setSearch(e.target.value)}
              onBlur={() => handleSearchAnalytics(search, sections.length)}
              value={search}
              placeholder="W czym możemy pomóc?"
              sx={{ width: "80%", maxWidth: "800px" }}
            />
          </Grid>
          <Grid item md={5} sx={{ display: { xs: "none", md: "block" }}}>
            <Box margin="0 auto">
              <StaticImage src="../assets/images/DokDok_App_Commercial_9_1000x1000.jpg" alt="front picture 2" loading="lazy"  style={{ width: "100%" }}  />
            </Box>
          </Grid>
          <Grid item container md={7} xs={12}>
            <Grid item container spacing={2} alignContent="flex-start" sx={{ mb: "4rem" }}>
              {
                sections.map((section) => (
                  <React.Fragment key={section.title}>
                    <Grid item xs={12} mb="-2rem" mt="2rem">
                      <Typography variant="h2" color="primary">{section.title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {
                        section.questions.map((item, index) => (
                          <Accordion key={item.question + index} label={item.question} id={item.question + index}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: item.answer
                              }}
                            />
                          </Accordion>
                        ))
                      }
                    </Grid>
                  </React.Fragment>
                ))
              }
              {
                (!sections.length && !!search.length) && (
                  <Grid item xs={12}>
                    <Typography textAlign="center" variant="h3">
                      Przykro nam, nie znaleźliśmy pasujących pytań i odpowiedzi dla podanej frazy.
                    </Typography>
                  </Grid>
                )
              }
            </Grid>
            <Grid item xs={12}>
              <Button color="primary" variant="contained" size="large" href="/">
                {t("goBackBtn")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Section>
    </SiteLayout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index", "faq"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;